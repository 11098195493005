import {
  ArrowLeftOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  SignalFilled,
} from '@ant-design/icons'

import { ReactComponent as dashboardSvg } from '../assets/images/dashboard.svg'
import { ReactComponent as initialSetup } from '../assets/images/initial_setup.svg'
import { ReactComponent as myDevice } from '../assets/images/my_device.svg'
import { ReactComponent as deviceSettingSvg } from '../assets/images/device-setings.svg'
import { ReactComponent as myApp } from '../assets/images/my_app.svg'
import { ReactComponent as wirelessInsight } from '../assets/images/wireless.svg'
import { ReactComponent as keyboard } from '../assets/images/keyboard.svg'
import { ReactComponent as androidUpdates } from '../assets/images/android_updates.svg'
import { ReactComponent as licensing } from '../assets/images/licensing.svg'
import { ReactComponent as myServices } from '../assets/images/my_services.svg'
import { ReactComponent as zipFileSvg } from '../assets/images/RXLogger/zipfile.svg'
import { ReactComponent as designStudioIconSvg } from '../assets/images/design_studio_icon.svg'
import { ReactComponent as deviceUsers } from '../assets/images/device_users.svg'
import { ReactComponent as rolesSvg } from '../assets/images/roles.svg'
import { ReactComponent as supportTools } from '../assets/images/supportTools.svg'
import { ReactComponent as rxLogger } from '../assets/images/rxLogger.svg'
import { ReactComponent as wirelessInsightsNew } from '../assets/images/wireless_insights_new.svg'
import {ReactComponent as wirelessInsightsIcon} from '../views/wi-views/utils/icons/wi-icon.svg'

const dashboardNavTree = [
  {
    key: 'home',
    path: '/app/home',
    title: 'Dashboard',
    iconImage: dashboardSvg,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: 'initialSetupNew',
    path: '/app/initialSetupNew',
    title: 'New Device Setup',
    iconImage: initialSetup,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'myDevice',
    path: '/app/myDevice',
    title: 'My Devices',
    iconImage: myDevice,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'deviceUsers',
    path: '/app/deviceUsers',
    title: 'Device Users',
    iconImage: deviceUsers,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'deviceSettings',
    path: '/app/deviceSettings',
    title: 'Device Settings',
    iconImage: deviceSettingSvg,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'myApps',
    path: '/app/myApps',
    title: 'My Apps',
    iconImage: myApp,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'designStudio',
    path: '/app/designStudio',
    title: 'Design Studio',
    iconImage: designStudioIconSvg,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //    key: 'rxLogger',
  //    path: '/app/rxLogger',
  //    title: 'Remote Rxlogger',
  //    iconImage: zipFileSvg,
  //    breadcrumb: false,
  //    submenu: [],
  //  },
  {
    key: 'androidUpdates',
    path: '/app/androidUpdates',
    title: 'Android Updates',
    iconImage: androidUpdates,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'licensing',
    path: '/app/licensing',
    title: 'Licensing',
    iconImage: licensing,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: 'wiInsight',
  //   path: '/app/wirelessInsight',
  //   title: 'Wireless Insights',
  //   icon: SignalFilled,
  //   // icon: wirelessInsight,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: 'supportTools',
    path: '',
    title: 'Support Tools',
    iconImage: supportTools,
    breadcrumb: false,
    submenu: [
      {
        key: 'remoteRxLogger',
        path: '/app/supportTools/remoteRxLogger',
        title: 'Remote Rxlogger',
        iconImage: rxLogger,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'wirelessInsights',
        path: '/app/supportTools/wirelessInsights',
        title: 'Wireless Insights',
        iconImage: wirelessInsightsIcon,
        breadcrumb: false,
        submenu: []
      }
    ],
  },
  // {
  //   key: 'myApps2',
  //   path: '/app/myApps',
  //   title: 'My Apps2',
  //   iconImage: androidUpdates,
  //   breadcrumb: false,
  //   submenu: [],
  // }
]

const userDashboardNavTree = [
  {
    key: 'appDashboard',
    path: '/app/home',
    title: 'Back',
    icon: ArrowLeftOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'myProfile',
    path: '/user/myProfile',
    title: 'My Profile',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'myServices',
    path: '/user/myServices',
    title: 'My Services',
    iconImage: myServices,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'roleManagement',
    path: '/user/roleManagement',
    title: 'Roles',
    iconImage: rolesSvg,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'userManagement',
    path: '/user/userManagement',
    title: 'Users',
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
]

const navigationConfig = (path) => {
  return path && path.includes('user/') ? [...userDashboardNavTree] : [...dashboardNavTree]
}

export default navigationConfig
